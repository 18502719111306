import { useMemo, useState } from 'react'
import moment from 'moment'
import { useLocalStorage } from '@/hooks/useLocalStorage'

import { H4, P, Muted } from '@/components/ui/typography'
import { ExternalLink } from 'lucide-react'
import { Loading } from '@/components/ui/loading'
import { Select } from '@/components/common/select'
import NineBoxGrid from '@/components/ninebox-grid'
import { TeamReviewOverviewItem } from '@/components/team-review-overview-item'
import { TeamReviewEditModal } from '@/components/team-review-edit-modal'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutTile } from '@/components/layout'
import { Checkbox } from '@/components/ui/checkbox'

import { useFetchTeamMemberPerformanceReviews } from '@/services/api/performanceReview.api'

import {
  Quarter,
  getQuarterFromDate,
  parseQuarterString,
} from '@/services/utils/dates'
import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'

import { TeamMemberPerformanceReview, StoredReviewVisibilityState } from '@/types/PerformanceReview'

interface StoredTeamReviewState {
  quarter: {
    label: string;
    value: Quarter;
  };
  employeeScope: 'all' | 'direct';
  showNineBox: boolean;
}

export const MyTeamReviewsPage = () => {
  const defaultState: StoredTeamReviewState = {
    quarter: getQuarterFromDate(moment().add({ quarter: -1 }).format()),
    employeeScope: 'direct',
    showNineBox: true
  };

  const [storedState, setStoredState] = useLocalStorage<StoredTeamReviewState>(
    'team-reviews.page-state',
    defaultState
  );

  const [selectedTeamReview, setSelectedTeamReview] = useState<
    TeamMemberPerformanceReview | undefined
  >(undefined);

  const [hiddenStates, setHiddenStates] = useLocalStorage<StoredReviewVisibilityState>(
    'team-reviews.visibility-states',
    {}
  );

  const {
    data: teamReviews = [],
    isLoading,
    error,
  } = useFetchTeamMemberPerformanceReviews(
    storedState.quarter.value,
    storedState.employeeScope === 'all',
  )

  const handleHideClick = (userId: string, hidden: boolean) => {
    setHiddenStates({
      ...hiddenStates,
      [userId]: hidden
    });
  }

  const nineBoxUsers = useMemo(() => {
    return teamReviews
      .filter((tr) => {
        const isHidden = tr.user.id in hiddenStates 
          ? hiddenStates[tr.user.id] 
          : tr.review?.hidden;
        return !isHidden;
      })
      .map((tr) => ({
        ...tr.user,
        objectivesScore: calculateObjectivesGrade(tr.review?.objectives ?? []),
        valuesScore: calculateValuesGrade(tr.review?.values ?? []),
      }))
  }, [teamReviews, hiddenStates])

  if (error) {
    return <ErrorTile />
  }

  return (
    <LayoutTile>
      <div className="flex flex-col sm:flex-row sm:items-start justify-between mb-4 space-y-4 sm:space-y-0 gap-8">
        <div className="max-w-md">
          <H4 className="font-semibold">Your Team&apos;s Reviews</H4>
          <P className="text-muted-foreground">
            View and manage performance reviews for your team members.{' '}
            <a
              href="https://youtu.be/yyPvyqrSdpo"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <ExternalLink size={16} className="ml-1" />
            </a>
          </P>
        </div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 shrink-0">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="show-nine-box"
              checked={storedState.showNineBox}
              onCheckedChange={(checked: boolean) => 
                setStoredState({ ...storedState, showNineBox: checked })
              }
            />
            <label
              htmlFor="show-nine-box"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Show 9-box
            </label>
          </div>
          <Select
            options={[
              { value: 'direct', label: 'Only direct reports' },
              { value: 'all', label: 'All employees under me' },
            ]}
            value={storedState.employeeScope}
            onValueChange={(value: 'all' | 'direct') => 
              setStoredState({ ...storedState, employeeScope: value })
            }
          />
          <Select
            className="w-[130px]"
            options={[
              { value: '2024 Q1', label: '2024 Q1' },
              { value: '2024 Q2', label: '2024 Q2' },
              { value: '2024 Q3', label: '2024 Q3' },
              { value: '2024 Q4', label: '2024 Q4' },
            ]}
            value={storedState.quarter.label}
            onValueChange={(value) =>
              setStoredState({
                ...storedState,
                quarter: parseQuarterString(value)
              })
            }
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : (
        <div>
          {storedState.showNineBox && (
            <div className="w-full max-w-3xl mx-auto">
              <NineBoxGrid 
                users={nineBoxUsers} 
                hiddenStates={hiddenStates}
              />
            </div>
          )}
          <div className="mt-4 flex flex-col gap-4">
            {teamReviews.length === 0 ? (
              <Muted className="my-16">
                No Team Reviews Available for this Quarter
              </Muted>
            ) : (
              teamReviews.map((review: TeamMemberPerformanceReview) => (
                <TeamReviewOverviewItem
                  key={`team-review-${review.user.id}`}
                  data={review}
                  handleEditClick={() => setSelectedTeamReview(review)}
                  selectedQuarter={storedState.quarter.value}
                  isHidden={!!(review.user.id in hiddenStates 
                    ? hiddenStates[review.user.id] 
                    : review.review?.hidden)}
                  onHideClick={(hidden) => handleHideClick(review.user.id, hidden)}
                />
              ))
            )}
            {!!selectedTeamReview && (
              <TeamReviewEditModal
                selectedReview={selectedTeamReview}
                setSelectedReview={setSelectedTeamReview}
                selectedQuarter={storedState.quarter.value}
              />
            )}
          </div>
        </div>
      )}
    </LayoutTile>
  )
}