import React, { useEffect, useState, useCallback } from 'react';
import { useAuth, useUser } from '@clerk/clerk-react';
import { TAWK_PROPERTY_ID, TAWK_WIDGET_ID } from '@/services/config/env';
import type { TawkMessengerProps } from '@/types/Tawk';
import { useTawk } from '@/hooks/useTawk';

export function TawkIntegration() {
  const { isLoaded, isSignedIn } = useAuth();
  const { user } = useUser();
  const [TawkMessenger, setTawkMessenger] = useState<React.ComponentType<TawkMessengerProps> | null>(null);
  const { setAttributes } = useTawk();

  useEffect(() => {
    const loadTawkMessenger = async () => {
      try {
        const TawkMessengerReact = (await import('@tawk.to/tawk-messenger-react')).default;
        setTawkMessenger(() => TawkMessengerReact);
        console.log('Tawk Messenger loaded successfully');
      } catch (error) {
        console.error('Failed to load Tawk Messenger:', error);
      }
    };

    loadTawkMessenger();
  }, []);

  const updateTawkAttributes = useCallback(() => {
    if (isLoaded && isSignedIn && user) {
      const attributes = {
        name: user.fullName || '',
        email: user.primaryEmailAddress?.emailAddress || '',
        userId: user.id || '',
      };
      console.log('Updating Tawk attributes:', attributes);
      setAttributes(attributes);
    } else {
      console.log('Not updating Tawk attributes. Auth state:', { isLoaded, isSignedIn, user: !!user });
    }
  }, [isLoaded, isSignedIn, user, setAttributes]);

  useEffect(() => {
    updateTawkAttributes();
  }, [updateTawkAttributes]);

  if (!TawkMessenger) {
    return null;
  }

  return (
    <TawkMessenger
      propertyId={TAWK_PROPERTY_ID}
      widgetId={TAWK_WIDGET_ID}
      onLoad={() => {
        console.log('Tawk Messenger loaded, calling updateTawkAttributes');
        updateTawkAttributes();
      }}
    />
  );
}