import { useMemo, useState } from 'react'
import { ChevronDown, Edit, Star } from 'lucide-react'

import { AvatarWithFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { Card } from '@/components/ui/card'
import { P, H5 } from '@/components/ui/typography'
import { Badge } from '@/components/ui/badge'
import { Icon } from '@/components/ui/icon'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'
import { cn } from '@/lib/utils'
import { getUserDisplayName } from '@/services/utils/formatters'
import { useUpdateTeamMemberPerformanceReview } from '@/services/api/performanceReview.api'

import { type Quarter } from '@/services/utils/dates'

import {
  PerformanceReview,
  TeamMemberPerformanceReview,
} from '@/types/PerformanceReview'

interface TeamReviewOverviewItemProps {
  data: TeamMemberPerformanceReview
  handleEditClick: () => void
  selectedQuarter: Quarter
  isHidden: boolean
  onHideClick: (_hidden: boolean) => void
}

interface ReviewOverviewItemContentProps {
  review: PerformanceReview
}

export const ReviewOverviewItemContent = ({
  review,
}: ReviewOverviewItemContentProps) => {
  const objectivesGrade = calculateObjectivesGrade(review.objectives)
  const valuesGrade = calculateValuesGrade(review.values)

  const getProgressBarWidth = (completion: number) => {
    return Math.min(completion, 100) + '%'
  }

  const getProgressBarColor = (completion: number) => {
    if (completion >= 90) return 'bg-primary'
    if (completion >= 60) return 'bg-yellow-500'
    return 'bg-red-500'
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div>
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-semibold text-lg">Objectives</h4>
          <div className="flex items-center">
            <span className="mr-2">Grade:</span>
            <span className="font-semibold">{objectivesGrade}/3</span>
          </div>
        </div>
        {review.objectives.map((objective, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between items-center mb-1">
              <span className="font-medium">{objective.title}</span>
              <span
                className={cn('font-semibold', {
                  'text-primary': objective.completion >= 90,
                  'text-yellow-500':
                    objective.completion < 90 && objective.completion >= 60,
                  'text-red-500': objective.completion < 60,
                })}
              >
                {objective.completion}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 relative">
              <div
                className={`h-2.5 rounded-full ${getProgressBarColor(objective.completion)}`}
                style={{
                  width: getProgressBarWidth(objective.completion),
                }}
              ></div>
              {objective.completion > 100 && (
                <div className="absolute top-0 right-0 h-full w-1 bg-green-500 rounded-r-full"></div>
              )}
            </div>
            <p className="text-sm text-gray-600 mt-1">{objective.comments}</p>
          </div>
        ))}
      </div>
      <div>
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-semibold text-lg">Values</h4>
          <div className="flex items-center">
            <span className="mr-2">Grade:</span>
            <span className="font-semibold">{valuesGrade}/3</span>
          </div>
        </div>
        {review.values.map((value, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between items-center mb-1">
              <span className="font-medium">{value.label}</span>
              <div>
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={cn('h-4 w-4 inline', {
                      'text-yellow-400 fill-yellow-400': i < value.rating,
                      'text-gray-300': i >= value.rating,
                    })}
                  />
                ))}
              </div>
            </div>
            <p className="text-sm text-gray-600">{value.comments}</p>
          </div>
        ))}
      </div>
      <div>
        <h4 className="font-semibold text-lg mb-2">Manager&apos;s Review</h4>
        <p className="text-sm">
          {review.managerReview || (
            <em className="text-gray-400">No review available</em>
          )}
        </p>
      </div>
      <div>
        <h4 className="font-semibold text-lg mb-2">Action Plan</h4>
        <p className="text-sm">
          {review.actionPlan || (
            <em className="text-gray-400">No action plan available</em>
          )}
        </p>
      </div>
    </div>
  )
}

const TeamReviewBadge = ({
  status,
}: {
  status?: PerformanceReview['status']
}) => {
  const OPTIONS: Record<
    PerformanceReview['status'],
    { color: string; text: string }
  > = {
    published: {
      color: 'bg-green-500',
      text: 'Published',
    },
    submitted: {
      color: 'bg-yellow-500',
      text: 'Submitted',
    },
    draft: {
      color: 'bg-orange-500',
      text: 'Draft',
    },
  }
  const option = status
    ? OPTIONS[status]
    : {
        color: 'bg-gray-400',
        text: 'N/A',
      }

  return (
    <Badge className={cn(option.color, `hover:${option.color}`)}>
      {option.text}
    </Badge>
  )
}

export const TeamReviewOverviewItem = ({
  data,
  handleEditClick,
  selectedQuarter,
  isHidden,
  onHideClick,
}: TeamReviewOverviewItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false)
  const hasReview = useMemo(() => !!data.review, [data.review])

  const { mutate: updateTeamMemberPerformanceReview } =
    useUpdateTeamMemberPerformanceReview(selectedQuarter)

  const handleHideClick = (hidden: boolean) => {
    onHideClick(hidden);
    
    if (data.review) {
      updateTeamMemberPerformanceReview({
        id: data.review.id,
        body: {
          hidden,
        },
      })
    }
  }

  const handlePublishClick = (newStatus: PerformanceReview['status']) => {
    if (data.review) {
      updateTeamMemberPerformanceReview({
        id: data.review.id,
        body: {
          status: newStatus,
        },
      })
    }
    setIsPublishDialogOpen(false)
  }

  return (
    <Card>
      <Collapsible open={isOpen} onOpenChange={setIsOpen} className="p-4">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-4 sm:space-y-0">
          <CollapsibleTrigger className="flex-grow text-left">
            <div className="flex items-center space-x-4">
              <AvatarWithFallback
                className="h-12 w-12 border-2 border-primary"
                image={data.user.image}
                fallback={`${data.user.firstName[0]}${data.user.lastName[0]}`}
              />
              <div>
                <div className="flex gap-2 items-center">
                  <H5>{getUserDisplayName(data.user)}</H5>
                  <TeamReviewBadge status={data?.review?.status} />
                </div>
                <P
                  className={cn(
                    'line-clamp-1 pt-1 text-sm flex-grow',
                    data.numberOfFeedbackPoints < 3
                      ? 'text-red-300'
                      : 'text-green-500',
                  )}
                >
                  {data.numberOfFeedbackPoints < 3
                    ? `Only ${data.numberOfFeedbackPoints} feedback point${data.numberOfFeedbackPoints > 1 ? 's' : ''} 🥺`
                    : `${data.numberOfFeedbackPoints} feedback point${data.numberOfFeedbackPoints > 1 ? 's' : ''} 🚀`}
                </P>
              </div>
            </div>
          </CollapsibleTrigger>
          <div className="flex gap-2 items-center">
            {hasReview && (
              <>
                {data?.review?.status === 'published' ? (
                  <Button
                    onClick={() => handlePublishClick('submitted')}
                    variant="outline"
                    size="sm"
                  >
                    <Icon name="ZapOff" className="h-4 w-4 mr-2" />
                    Unpublish
                  </Button>
                ) : (
                  <Dialog open={isPublishDialogOpen} onOpenChange={setIsPublishDialogOpen}>
                    <DialogTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                      >
                        <Icon
                          name="Zap"
                          className="h-4 w-4 mr-2 text-yellow-500"
                        />
                        Publish
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Confirm publication</DialogTitle>
                        <DialogDescription>
                          Publishing this review will trigger an email notification to {getUserDisplayName(data.user)}. Are you sure you want to proceed?
                        </DialogDescription>
                      </DialogHeader>
                      <DialogFooter className="mt-4">
                        <Button variant="outline" onClick={() => setIsPublishDialogOpen(false)}>
                          Cancel
                        </Button>
                        <Button onClick={() => handlePublishClick('published')}>
                          Confirm
                        </Button>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                )}
                <Button
                  onClick={() => handleHideClick(!isHidden)}
                  variant="outline"
                  size="sm"
                  className={cn(isHidden && 'text-amber-600')}
                >
                  {isHidden ? (
                    <>
                      <Icon name="Eye" className="h-4 w-4 mr-2" />
                      Display
                    </>
                  ) : (
                    <>
                      <Icon name="EyeOff" className="h-4 w-4 mr-2" />
                      Hide
                    </>
                  )}
                </Button>
              </>
            )}
            <Button onClick={handleEditClick} variant="outline" size="sm">
              <Edit className="h-4 w-4 mr-2" />
              {hasReview ? 'Edit' : 'Write'}
            </Button>
            {hasReview && (
              <Button
                variant="ghost"
                size="sm"
                className="w-9 p-0"
                onClick={() => setIsOpen(!isOpen)}
              >
                <ChevronDown
                  className={cn(
                    'h-4 w-4 transition-transform duration-200',
                    {
                      'transform rotate-180': isOpen,
                    },
                  )}
                />
                <span className="sr-only">Toggle</span>
              </Button>
            )}
          </div>
        </div>
        {hasReview && (
          <CollapsibleContent className="mt-4">
            <ReviewOverviewItemContent review={data.review!} />
          </CollapsibleContent>
        )}
      </Collapsible>
    </Card>
  )
}
