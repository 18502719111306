import { useState, useEffect, useCallback } from 'react';
import { TawkAPI, TawkAttributes } from '@/types/Tawk';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export function useTawk() {
  const [tawkApi, setTawkApi] = useState<TawkAPI | null>(null);

  useEffect(() => {
    const checkTawkReady = () => {
      if (window.Tawk_API && typeof window.Tawk_API.setAttributes === 'function') {
        setTawkApi(window.Tawk_API);
        console.log('Tawk API is ready');
      } else {
        setTimeout(checkTawkReady, 100);
      }
    };

    checkTawkReady();

    return () => {
      setTawkApi(null);
    };
  }, []);

  const setAttributes = useCallback((attributes: TawkAttributes, retryCount = 0) => {
    if (tawkApi && tawkApi.setAttributes) {
      try {
        console.log('Attempting to set Tawk attributes:', attributes);
        tawkApi.setAttributes(attributes, (error) => {
          if (error) {
            console.error('Error setting Tawk user attributes:', error);
            if (retryCount < MAX_RETRIES) {
              console.log(`Retrying setAttributes (attempt ${retryCount + 1})`);
              setTimeout(() => setAttributes(attributes, retryCount + 1), RETRY_DELAY);
            } else {
              console.error('Max retries reached. Failed to set Tawk attributes.');
            }
          } else {
            console.log('Tawk attributes set successfully');
          }
        });
      } catch (error) {
        console.error('Error calling Tawk setAttributes:', error);
      }
    } else {
      console.warn('Tawk API not ready. Attributes not set:', attributes);
      if (retryCount < MAX_RETRIES) {
        console.log(`Retrying setAttributes (attempt ${retryCount + 1})`);
        setTimeout(() => setAttributes(attributes, retryCount + 1), RETRY_DELAY);
      } else {
        console.error('Max retries reached. Failed to set Tawk attributes.');
      }
    }
  }, [tawkApi]);

  return { tawkApi, setAttributes };
}