import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  ConnectionLineType,
  Handle,
  Position,
  Edge,
  Node,
  useEdges,
  useEdgesState,
  useReactFlow,
} from '@xyflow/react'
import dagre from '@dagrejs/dagre'

import { Muted } from '@/components/ui/typography'
import { AvatarWithFallback } from '@/components/ui/avatar'

import {
  getUserAvatarFallback,
  getUserDisplayName,
} from '@/services/utils/formatters'

import { UserProfile } from '@/types/UserProfile'
import { OrganizationChartNode } from '@/types/OrganizationChart'

import '@xyflow/react/dist/style.css'
import { Separator } from './ui/separator'
import { cn } from '@/lib/utils'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Select } from '@/components/common/select'
import { Label } from '@/components/ui/label'
import { useOrganization } from '@/hooks/useOrganization'
import { Tooltip } from '@/components/common/tooltip'

const nodeWidth = 200
const nodeHeight = 120

const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({ rankdir: 'TB' })

  // Filter visible nodes
  const visibleNodes = nodes.filter((node) => !node.hidden)

  // Only add visible nodes to the dagre graph
  visibleNodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  // Only add edges where both source and target nodes are visible
  const visibleNodeIds = new Set(visibleNodes.map((node) => node.id))
  const visibleEdges = edges.filter(
    (edge) =>
      visibleNodeIds.has(edge.source) && visibleNodeIds.has(edge.target),
  )

  visibleEdges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  // Map positions only for visible nodes, keep hidden nodes unchanged
  const newNodes = nodes.map((node) => {
    // If node is hidden (hidden === false), return it unchanged
    if (node.hidden === true) {
      return node
    }

    const nodeWithPosition = dagreGraph.node(node.id)
    const newNode = {
      ...node,
      targetPosition: 'top',
      sourcePosition: 'bottom',
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    } as Node
    return newNode
  })

  return { nodes: newNodes }
}

const OrgChartNode = ({
  data,
}: {
  data: UserProfile & {
    isCollapsed: boolean
    selected: boolean
    onClickExpand: (
      _e: React.MouseEvent<HTMLButtonElement>,
      _nodeId: string,
    ) => void
  }
}) => {
  const [hover, setHover] = useState(false)

  const edges = useEdges()
  const displayName = useMemo(() => getUserDisplayName(data), [data])
  const directReports = useMemo(
    () => edges.filter((edge) => edge.source === data.id).length,
    [data.id, edges],
  )

  return (
    <>
      <div
        className={cn(
          `w-[${nodeWidth}px] p-4 flex flex-col rounded-xl border bg-white cursor-pointer hover:opacity-80`,
          data.selected && 'border-green-600',
          data.selected && 'border-2',
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="flex flex-col gap-3 items-center">
          <AvatarWithFallback
            className="h-12 w-12 border-2 border-primary"
            image={data.image}
            fallback={getUserAvatarFallback(data)}
          />
          <Muted>{displayName}</Muted>
        </div>
      </div>
      {!!directReports && (
        <Button
          className="absolute -bottom-2 left-1/2 -translate-x-1/2 w-5 h-5 rounded-full bg-primary flex items-center justify-center z-10"
          variant="ghost"
          size="icon"
          onClick={(e) => data.onClickExpand(e, data.id)}
        >
          <span className="text-xs font-medium text-white">
            {!hover ? (
              directReports
            ) : (
              <Icon
                name={data.isCollapsed ? 'ChevronDown' : 'ChevronUp'}
                className="w-3 h-3"
              />
            )}
          </span>
        </Button>
      )}
      <Handle
        type="target"
        position={Position.Top}
        style={{ top: 0, opacity: 0 }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ bottom: 0, top: 'auto', opacity: 0 }}
      />
    </>
  )
}

const nodeTypes = { member: OrgChartNode }

interface OrgChartProps {
  orgChart: OrganizationChartNode[]
}

export const OrgChart = ({ orgChart = [] }: OrgChartProps) => {
  const { getNodes, getEdges } = useReactFlow()
  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined)
  const [nodes, setNodes] = useNodesState<Node>([])
  const [, setEdges] = useEdgesState<Edge>([])

  /** Helper function to get all descendant node IDs */
  const getDescendants = useCallback(
    (
      nodeId: string,
      edges: Edge[],
      level = 1,
    ): { nodeId: string; level: number }[] => {
      const children = edges
        .filter((edge) => edge.source === nodeId)
        .map((edge) => ({ nodeId: edge.target, level }))

      const descendants = [...children]
      children.forEach((node) => {
        descendants.push(...getDescendants(node.nodeId, edges, level + 1))
      })

      return descendants
    },
    [],
  )

  const handleExpandClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, nodeId: string) => {
      e.stopPropagation()

      let newNodes = getNodes()
      let newEdges = getEdges()
      const node = newNodes.find((n) => n.id === nodeId)!

      newNodes = newNodes.map((n) => ({
        ...n,
        data: {
          ...n.data,
          isCollapsed:
            n.id === node.id ? !n.data.isCollapsed : n.data.isCollapsed,
        },
      }))
      const descendants = getDescendants(node.id, newEdges)

      if (!node.data.isCollapsed) {
        // Hide all descendant nodes and their edges
        newNodes = newNodes.map((n) => ({
          ...n,
          hidden: descendants.some((d) => d.nodeId === n.id) ? true : n.hidden,
        }))
        newEdges = newEdges.map((e) => ({
          ...e,
          hidden:
            descendants.some((d) => d.nodeId === e.target) ||
            descendants.some((d) => d.nodeId === e.source)
              ? true
              : e.hidden,
        }))
      } else {
        // Show all descendant nodes and their edges
        newNodes = newNodes.map((n) => ({
          ...n,
          hidden: descendants.some((d) => d.level === 1 && d.nodeId === n.id)
            ? false
            : n.hidden,
        }))
        newEdges = newEdges.map((e) => ({
          ...e,
          hidden:
            descendants.some((d) => d.level === 1 && d.nodeId == e.target) ||
            descendants.some((d) => d.level === 1 && d.nodeId === e.source)
              ? false
              : e.hidden,
        }))
      }

      const { nodes: layoutedNodes } = getLayoutedElements(newNodes, newEdges)

      setNodes(layoutedNodes)
      setEdges(newEdges)
    },
    [setNodes, setEdges, getEdges, getDescendants, getNodes],
  )

  const transformedNodes = useMemo<Node[]>(
    () =>
      orgChart.map((o) => ({
        id: o.user.id,
        type: 'member',
        position: { x: 0, y: 0 },
        data: {
          ...o.user,
          onClickExpand: handleExpandClick,
        } as unknown as Record<string, unknown>,
      })),
    [orgChart, handleExpandClick],
  )
  const transformedEdges = useMemo<Edge[]>(
    () =>
      orgChart
        .filter((n) => !!n.managerId)
        .map((n) => ({
          id: crypto.randomUUID(),
          source: n.managerId!,
          target: n.user.id,
          type: 'smoothstep',
        })),
    [orgChart],
  )
  const { nodes: layoutedNodes } = useMemo(
    () => getLayoutedElements(transformedNodes, transformedEdges),
    [transformedEdges, transformedNodes],
  )

  useEffect(() => {
    setNodes([...layoutedNodes])
  }, [layoutedNodes, setNodes])

  const handleNodeClick = useCallback(
    (_e: unknown, elem: Node) => {
      const nodes = getNodes()
      const newNodes = nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          selected: node.id === elem.id,
        },
      }))
      setNodes(newNodes)
      setSelectedNode(elem)
    },
    [getNodes, setNodes],
  )

  const onCloseMemberInfo = () => {
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: {
          ...n.data,
          selected: false,
        },
      })),
    )
    setSelectedNode(undefined)
  }

  return (
    <div className="h-full flex">
      <ReactFlow
        className="flex-1"
        nodes={nodes}
        edges={transformedEdges}
        nodeTypes={nodeTypes}
        connectionLineType={ConnectionLineType.SmoothStep}
        maxZoom={1}
        onNodeClick={handleNodeClick}
        fitView
        nodesConnectable={false}
        connectOnClick={false}
      >
        <Background />
        <Controls />
      </ReactFlow>
      <OrganizationMemberInfo
        user={selectedNode?.data as unknown as UserProfile}
        onClose={onCloseMemberInfo}
      />
    </div>
  )
}

interface OrganizationMemberInfoProps {
  user?: UserProfile
  onClose?: () => void
}

const OrganizationMemberInfo = ({
  user,
  onClose,
}: OrganizationMemberInfoProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const { users, updateUserManager } = useOrganization()

  const managerOptions = useMemo(() => {
    if (!user) return []
    return users
      .filter((u: UserProfile) => u.id !== user.id)
      .map((u: UserProfile) => ({
        value: u.id,
        label: getUserDisplayName(u)
      }))
  }, [users, user])

  const handleManagerChange = async (newManagerId: string) => {
    if (!user) return
    await updateUserManager(user.id, newManagerId)
  }

  const handleClose = () => {
    setIsVisible(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (user) {
      return setIsVisible(true)
    }
    setIsVisible(false)
  }, [user])

  return (
    <div
      className={`
        w-[300px] flex flex-col gap-4 bg-white border-l p-4
        fixed right-0 top-0 h-full
        transform transition-transform duration-200 ease-in-out
        ${isVisible ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      <Button
        className="absolute top-2 right-2"
        variant="ghost"
        size="icon"
        onClick={handleClose}
      >
        <Icon name="X" className="h-4 w-4" />
      </Button>
      {!!user && (
        <>
          <div className="flex flex-col gap-3 items-center">
            <AvatarWithFallback
              className="h-12 w-12 border-2 border-primary"
              image={user.image}
              fallback={getUserAvatarFallback(user)}
            />
            <Muted>{getUserDisplayName(user)}</Muted>
          </div>
          <Separator />
          
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Email</Label>
              <div className="mt-0">
                <Muted className="break-all">{user.emails[0]}</Muted>
              </div>
            </div>

            <div className="space-y-2">
              <Label>Manager</Label>
              <Tooltip content="Manager selection will be available soon">
                <div>
                  <Select
                    options={managerOptions}
                    value={user.managerId || ''}
                    onValueChange={handleManagerChange}
                    placeholder="Select manager"
                    className="w-full"
                    disabled={true}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
