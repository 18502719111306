import {
  Select as UISelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { ClassName } from '@/types/globals'

interface SelectOption {
  value: string
  label: string
}

interface SelectProps<T extends string> {
  options: SelectOption[]
  value: T
  // eslint-disable-next-line no-unused-vars
  onValueChange: (value: T) => void
  placeholder?: string
  className?: ClassName
  disabled?: boolean
}

export const Select = <T extends string>({
  options,
  value: _value,
  onValueChange,
  placeholder = 'Select an option',
  className = '',
  disabled,
}: SelectProps<T>) => {
  return (
    <UISelect value={_value} onValueChange={onValueChange} disabled={disabled}>
      <SelectTrigger className={cn('w-[200px]', className)}>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </UISelect>
  )
}

