import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UserButton, useAuth } from '@clerk/clerk-react'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Icon, IconName } from '@/components/ui/icon'
import { LogoLink } from '@/components/common/logo'
import { CustomOrganizationSwitcher } from '@/components/custom-organization-switcher'

import { useFetchTeamMembers } from '@/services/api/organization.api'
import { cn } from '@/lib/utils'

import { CannyOptions } from '@/types/Canny'
import { UserProfile } from '@/types/UserProfile'
import { TawkAPI } from '@/types/Tawk'

interface MenuOption {
  label: string
  href: string
  icon: IconName
  disabled?: boolean
  submenu?: MenuOption[]
}

const getMenuOptions = (directReports: UserProfile[]): MenuOption[] => [
  {
    label: 'Dashboard',
    icon: 'LayoutDashboard',
    href: '/',
  },
  {
    label: 'Feedback Points',
    icon: 'TrendingUp',
    href: '/feedback-points',
  },
  {
    label: 'Feedback Sessions',
    icon: 'Star',
    href: '/feedback-sessions',
  },
  {
    label: 'Objectives',
    icon: 'Crosshair',
    href: '/objectives',
  },
  {
    label: 'Performance Reviews',
    icon: 'ClipboardCheck',
    href: '/performance-review',
    submenu: [
      {
        label: 'My Reviews',
        icon: 'User',
        href: '/performance-review/me',
      },
      {
        label: 'My Team Reviews',
        icon: 'Users',
        href: '/performance-review/team',
        disabled: !directReports.length,
      },
    ],
  },
  {
    label: 'Organizational Chart',
    icon: 'Users',
    href: '/organizational-chart',
  },
  {
    label: 'Time Off',
    icon: 'Calendar',
    href: '/time-off',
    disabled: true,
  },
]

const MenuOptionItem = ({
  label,
  href,
  icon,
  disabled,
  submenu,
}: MenuOption) => {
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  const isActive =
    pathname === href ||
    (submenu && submenu.some((item) => pathname === item.href))

  return (
    <div>
      <Link
        to={!disabled && !submenu ? href : '#'}
        className={cn(
          'flex items-center gap-2.5 rounded-md py-2 px-3 text-muted-foreground transition-all hover:bg-accent hover:text-accent-foreground text-sm',
          isActive && !submenu && 'text-white bg-green-600 hover:bg-green-600/90 hover:text-white',
          disabled && 'opacity-50 hover:opacity-50 cursor-not-allowed',
          submenu && 'cursor-pointer',
          submenu && isActive && 'text-green-600 font-medium',
        )}
        onClick={() => submenu && setIsOpen(!isOpen)}
      >
        <Icon name={icon} className="h-4 w-4" />
        <span>{label}</span>
        {submenu && (
          <Icon
            name={isOpen ? 'ChevronUp' : 'ChevronDown'}
            className="ml-auto h-4 w-4"
          />
        )}
      </Link>
      {submenu && isOpen && (
        <div className="ml-4 mt-1 space-y-1">
          {submenu.map((subItem) => (
            <MenuOptionItem key={subItem.label} {...subItem} />
          ))}
        </div>
      )}
    </div>
  )
}

const LogoutButton = () => {
  const { signOut } = useAuth()

  return (
    <div className="flex items-center gap-2">
      <UserButton afterSignOutUrl="/sign-in" />
      <Button
        variant="link"
        className="flex gap-2"
        onClick={() => signOut({ redirectUrl: '/sign-in' })}
      >
        <Icon name="LogOut" />
        Log Out
      </Button>
    </div>
  )
}

const SupportButton = ({ onClick }: { onClick: () => void }) => (
  <div
    className="flex items-center gap-2.5 rounded-md py-2 px-3 text-muted-foreground transition-all hover:bg-accent hover:text-accent-foreground cursor-pointer text-sm font-medium"
    onClick={onClick}
  >
    <Icon name="CircleHelp" className="h-4 w-4" />
    <span>Support</span>
  </div>
)

const ChangelogButton = () => (
  <div
    className="flex items-center gap-2.5 rounded-md py-2 px-3 text-muted-foreground transition-all hover:bg-accent hover:text-accent-foreground cursor-pointer text-sm font-medium"
    data-canny-changelog
  >
    <Icon name="Inbox" className="h-4 w-4" />
    <span>Changelog</span>
  </div>
)

const CannyChangelog = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Canny) {
      window.Canny('initChangelog', {
        appID: '66ad67a82ec2f62f925c7e66',
        position: 'top',
        align: 'left',
        theme: 'light',
      } as CannyOptions)
    }
  }, [])

  return null
}

export const MenuDesktop = () => {
  const { data: directReports } = useFetchTeamMembers({
    onlyDirectReports: true,
  })
  const toggleTawk = () => {
    const tawkApi = window.Tawk_API as TawkAPI | undefined
    if (tawkApi && typeof tawkApi.toggle === 'function') {
      tawkApi.toggle()
    }
  }

  return (
    <div className="hidden border-r bg-background md:block">
      <div className="flex h-full max-h-screen flex-col">
        <div className="flex h-16 items-center border-b px-6">
          <LogoLink size="normal" />
        </div>
        <div className="flex-1 flex flex-col justify-between overflow-auto">
          <nav className="flex flex-col px-4 py-2 text-sm font-medium space-y-1">
            {getMenuOptions(directReports).map((option) => (
              <MenuOptionItem key={option.label} {...option} />
            ))}
          </nav>
          <div className="px-4 py-2 mt-auto space-y-1">
            <ChangelogButton />
            <SupportButton onClick={toggleTawk} />
            <CustomOrganizationSwitcher />
            <LogoutButton />
          </div>
        </div>
      </div>
      <CannyChangelog />
    </div>
  )
}

export const MenuMobile = () => {
  const { data: directReports } = useFetchTeamMembers({
    onlyDirectReports: true,
  })
  const toggleTawk = () => {
    const tawkApi = window.Tawk_API as TawkAPI | undefined
    if (tawkApi && typeof tawkApi.toggle === 'function') {
      tawkApi.toggle()
    }
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="shrink-0 md:hidden text-green-600"
        >
          <Icon name="Menu" className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col p-0 w-[280px] sm:w-[300px]">
        <div className="flex h-16 items-center border-b px-6">
          <LogoLink className="flex-1" />
        </div>
        <div className="flex-1 overflow-auto">
          <nav className="grid px-4 py-2 gap-1">
            {getMenuOptions(directReports).map((option) => (
              <MenuOptionItem key={option.label} {...option} />
            ))}
          </nav>
        </div>
        <div className="border-t px-4 py-2 space-y-1">
          <ChangelogButton />
          <SupportButton onClick={toggleTawk} />
          <CustomOrganizationSwitcher />
          <LogoutButton />
        </div>
        <CannyChangelog />
      </SheetContent>
    </Sheet>
  )
}
