import React, { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { getUserDisplayName } from '@/services/utils/formatters';
import { CannyOptions } from '@/types/Canny';

interface CannyProviderProps {
  children: React.ReactNode;
}

export const CannyProvider: React.FC<CannyProviderProps> = ({ children }) => {
  const { user } = useUser();

  useEffect(() => {
    if (user && window.Canny) {
      const cannyOptions: CannyOptions = {
        appID: '66ad67a82ec2f62f925c7e66',
        user: {
          email: user.primaryEmailAddress?.emailAddress ?? undefined,
          name: getUserDisplayName(user),
          id: user.id,
          avatarURL: user.imageUrl ?? undefined,
          created: user.createdAt
            ? new Date(user.createdAt).toISOString()
            : undefined,
        },
      };
      window.Canny('identify', cannyOptions);
    }
  }, [user]);

  return <>{children}</>;
};