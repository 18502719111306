import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'

import { addQueryParams, fetchResolve } from './helpers'

import { UserProfile } from '@/types/UserProfile'
import { OrganizationChartNode } from '@/types/OrganizationChart'

export const useFetchTeamMembers = (options?: {
  onlyDirectReports?: boolean
}) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<UserProfile[]>({
    queryKey: ['useFetchTeamMembers', options, organization?.id],
    queryFn: () =>
      fetchResolve(
        addQueryParams(`/organization/${organization?.id}/user`, {
          onlyDirectReports: options?.onlyDirectReports,
        }),
        getToken,
      ),
    initialData: [],
  })
}

export const useFetchTeamValues = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<{ label: string; id: string }[]>({
    queryKey: ['useFetchTeamValues', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/values`, getToken),
    initialData: [],
  })
}

export const useFetchOrganizationChart = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<OrganizationChartNode[]>({
    queryKey: ['useFetchOrganizationChart', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/chart`, getToken),
  })
}

export const updateUser = async (orgId: string, userId: string, data: Partial<UserProfile>) => {
  const response = await fetch(`/organization/${orgId}/user/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return response.json()
}
