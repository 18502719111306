import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ReactQueryClientProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { toast } = useToast()

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update

        if (query.state.data !== undefined) {
          toast(createServerErrorToast(error.message))
        }
      },
    }),
    defaultOptions: {
      queries: {},
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default ReactQueryClientProvider
