import { ErrorTile } from '@/components/common/error-tile'
import { Loading } from '@/components/ui/loading'
import { OrgChart } from '@/components/org-chart'

import { useFetchOrganizationChart } from '@/services/api/organization.api'

export const OrganizationChartPage = () => {
  const { data: orgChart, isLoading, error } = useFetchOrganizationChart()

  if (error) {
    return <ErrorTile />
  }

  return (
    <div className="flex-1">
      {isLoading || !orgChart ? (
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      ) : (
        <OrgChart orgChart={orgChart} />
      )}
    </div>
  )
}
