import React, { useEffect } from 'react'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { setCompanyProfile } from '@/services/mocks/companyProfileManager'

interface DemoCompanyProfileWrapperProps {
  children: React.ReactNode
}

export const DemoCompanyProfileWrapper: React.FC<DemoCompanyProfileWrapperProps> = ({ children }) => {
  const [storedDemoCompany, setStoredDemoCompany] = useLocalStorage<string>(
    'demo-company-profile',
    'default'
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const urlDemoCompany = searchParams.get('demo_company')
    
    // If there's a demo_company in the URL, update both storage and company profile
    if (urlDemoCompany && urlDemoCompany !== storedDemoCompany) {
      setStoredDemoCompany(urlDemoCompany)
      setCompanyProfile(urlDemoCompany)
    } else {
      // Otherwise use the stored value
      setCompanyProfile(storedDemoCompany)
    }
  }, [storedDemoCompany, setStoredDemoCompany])

  return <>{children}</>
}
