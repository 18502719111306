import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { deleteResolve, fetchResolve } from './helpers'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import { FeedbackStats } from '@/types/FeedbackStats'
import { FeedbackPoint } from '@/types/FeedbackPoint'
import { MOCK_SERVER_ENABLED } from '../config/env'

export const useFetchFeedbackPoints = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackPoint[]>({
    queryKey: ['useFetchFeedbackPoints', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/feedback/point`,
        getToken,
      ),
    enabled: MOCK_SERVER_ENABLED || !!organization?.id,
  })
}

export const useFetchFeedbackStats = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackStats>({
    queryKey: ['useFetchFeedbackStats', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/feedback/stats`,
        getToken,
      ),
    initialData: {
      total: 0,
      done: {
        meets_checklist: 0,
        does_not_meet_checklist: 0,
      },
      missedProactiveFeedback: 0,
      checklistCompliance: 0,
      weekDetails: [],
      companyValuesAverage: {},
      companyValuesKeys: [],
    },
    enabled: MOCK_SERVER_ENABLED || !!organization?.id,
  })
}

export const useDeleteFeedbackPoint = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      await deleteResolve(
        `/organization/${organization?.id}/feedback/point/${id}`,
        getToken,
      ),
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['useFetchFeedbackPoints', organization?.id],
        (oldData: FeedbackPoint[]) =>
          oldData && oldData.filter((fp) => fp.id !== variables.id),
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}
