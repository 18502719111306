import * as React from 'react'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'

import { cn } from '@/lib/utils'

const Collapsible = CollapsiblePrimitive.Root

const CollapsibleTrigger = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.CollapsibleTrigger>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.CollapsibleTrigger>
>(({ children, className, ...props }, ref) => (
  <CollapsiblePrimitive.CollapsibleTrigger
    ref={ref}
    className={cn('w-full text-start', className)}
    {...props}
  >
    {React.Children.map(children, child => 
      React.isValidElement(child) && 'onClick' in child.props
        ? React.cloneElement(child as React.ReactElement<{ onClick?: React.MouseEventHandler }>, { 
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation()
              if (child.props.onClick) {
                child.props.onClick(e)
              }
            }
          })
        : child
    )}
  </CollapsiblePrimitive.CollapsibleTrigger>
))
CollapsibleTrigger.displayName = 'CollapsibleTrigger'

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent

export { Collapsible, CollapsibleTrigger, CollapsibleContent }
