import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useFetchTeamMembers, updateUser } from '@/services/api/organization.api'
import { useOrganization as useClerkOrganization } from '@clerk/clerk-react'

export function useOrganization() {
  const queryClient = useQueryClient()
  const { organization } = useClerkOrganization()
  const { data: users = [] } = useFetchTeamMembers()

  const updateUserManager = useCallback(async (userId: string, managerId: string) => {
    if (!organization?.id) return
    await updateUser(organization.id, userId, { managerId })
    queryClient.invalidateQueries({ queryKey: ['useFetchTeamMembers'] })
    queryClient.invalidateQueries({ queryKey: ['useFetchOrganizationChart'] })
  }, [organization?.id, queryClient])

  return {
    users,
    updateUserManager
  }
} 