import { Icon } from '@/components/ui/icon'
import { Muted } from '../ui/typography'

export const ErrorTile = () => (
  <div className="flex-1 rounded-2xl flex flex-col items-center justify-center gap-2 bg-white">
    <Icon name="ShieldAlert" className="w-12 h-12 text-rose-500" />
    <Muted className="font-medium">
      Something went wrong, please try again.
    </Muted>
  </div>
)
