import { Navigate, createBrowserRouter } from 'react-router-dom'
import { RedirectToUserProfile } from '@clerk/clerk-react'
import { ReactFlowProvider } from '@xyflow/react'

import { ProtectedPage } from '@/components/common/protected-page'

/* Routes */
import { Layout } from '@/components/layout'
import { Root } from '@/components/root'
import { FeedbackPointsPage } from '@/routes/feedback-points'
import { FeedbackSessionsPage } from '@/routes/feedback-sessions'
import { SignInPage } from '@/routes/auth/sign-in'
import { Dashboard } from '@/routes/dashboard'
import { SlackSuccessPage } from '@/routes/slack/success'
import { ObjectivesPage } from '@/routes/objectives'
import { MyReviewsPage } from '@/routes/performance-review/me'
import { MyTeamReviewsPage } from '@/routes/performance-review/team'
import { OrganizationChartPage } from './routes/organization-chart'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      // Path used to initiate Clerk Sign In component
      {
        path: '/sign-in',
        element: <SignInPage />,
      },
      // Path used to handle the verification of an auth Link sign-in
      {
        path: '/sign-in/verify',
        element: <SignInPage />,
      },
      // Path used to handle the delivery of an auth link used for sign-in
      {
        path: '/sign-in/factor-one',
        element: <SignInPage />,
      },
      {
        path: '/slack/success',
        element: <SlackSuccessPage />,
      },
      {
        path: '/',
        element: <ProtectedPage />,
        children: [
          {
            path: '/',
            element: <Layout />,
            children: [
              {
                path: '/',
                element: <Dashboard />,
              },
              {
                path: '/account',
                element: <RedirectToUserProfile />,
              },
              {
                path: '/feedback-points',
                element: <FeedbackPointsPage />,
              },
              {
                path: '/feedback-sessions',
                element: <FeedbackSessionsPage />,
              },
              {
                path: '/objectives',
                element: <ObjectivesPage />,
              },
              {
                path: '/performance-review',
                children: [
                  {
                    index: true,
                    element: <Navigate to="me" replace />,
                  },
                  {
                    path: 'me',
                    element: <MyReviewsPage />,
                  },
                  {
                    path: 'team',
                    element: <MyTeamReviewsPage />,
                  },
                ],
              },
              {
                path: '/organizational-chart',
                element: (
                  <ReactFlowProvider>
                    <OrganizationChartPage />
                  </ReactFlowProvider>
                ),
              },
            ],
          },
          {
            path: '/*',
            element: <Navigate to="/" />,
          },
        ],
      },
    ],
  },
])
